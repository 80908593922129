import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";


const Pagination = props => {

  const loadParts = () => {
    if (!props.paginationLoading) {
      props.nextPage()
    }
  }

  const buttonClasses = classNames(('btn btn-outline-dark w-100'), { disabled: props.paginationLoading })
  const spinnerClasses = classNames(('spinner-border spinner-border-sm me-1'), {'invisible': !props.paginationLoading})
  const buttonLabel = props.paginationLoading ? 'Загружаем' : 'Показать еще'

  if (props.paginationVisible) {
    return(
      <div className='col-12'>
        <div
          className={buttonClasses}
          disabled={props.paginationLoading}
          onClick={() => loadParts()}
        >
          <span className={spinnerClasses} role="status" aria-hidden="true"/>
          {buttonLabel}
        </div>
      </div>
    )
  } else {
    return null
  }
}

Pagination.propTypes = {
  nextPage: PropTypes.func.isRequired,
  paginationVisible: PropTypes.bool.isRequired,
  paginationLoading: PropTypes.bool.isRequired
};

export default Pagination
