import React from 'react';
import PropTypes from 'prop-types';
import CartButton from "./CartButton";

const CarPart = props => {

  const { part, cartIds, addToCart } = props

  return (
    <div className='card'>
      <a href={part.url}>
        <img
          src={part.main_image}
          className="card-img-top img-fluid rounded"
          alt={`${part.name} для ${part.make} ${part.model}`} />
      </a>
      <div className='card-body'>
        <h5 className='card-title'>{part.name}</h5>
        <p className='card-text'>{`${part.make} ${part.model}`}</p>
        <strong>{part.price}</strong>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-6'>
            <a href={part.url} className='btn btn-info btn-sm card-link w-100'>Подробнее</a>
          </div>
          <div className='col-6'>
            <CartButton cartIds={cartIds} id={part.id} addToCart={addToCart} />
          </div>
        </div>
      </div>
    </div>
  );
};

CarPart.propTypes = {
  cartIds: PropTypes.array.isRequired,
  part: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired
};

export default CarPart;
