import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import CarsList from "./CarsList"
import CarParts from "./CarParts"
import {authHeaders} from "../helpers/auth-headers";


class Cars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelsSelectDisabled: true,
      isPartsSelectDisabled: true,
      modelSelectPlaceholder: 'Модель автомобиля',
      carMakes: [],
      carModels: [],
      carParts: [],
      carPartNames: [],
      partsOptions: [],
      selectedMake: null,
      selectedModel: null,
      selectedPart: null,
      paginationLoading: false
    }

    this.handleMakesChange = this.handleMakesChange.bind(this)
    this.loadParts = this.loadParts.bind(this)
    this.handleModelsSelectChange = this.handleModelsSelectChange.bind(this)
    this.handlePartsInputChange = this.handlePartsInputChange.bind(this)
    this.handlePartsSelectChange = this.handlePartsSelectChange.bind(this)
    this.setPartsOptions = this.setPartsOptions.bind(this)
    this.setMakeSelectsValue = this.setMakeSelectsValue.bind(this)
    this.setModelSelectsValue = this.setModelSelectsValue.bind(this)
    this.setModelsOptions = this.setModelsOptions.bind(this)
    this.initialize = this.initialize.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.getCartIds = this.getCartIds.bind(this)
  }

  componentDidMount() {
    let options = []

    this.props.carMakes.map((car) => (
      options.push({label: car.make, value: car.make})
    ))
    this.setState({
      carMakes: options,
    })

    this.getCartIds()
    this.initialize()
  }

  initialize() {
    this.setState({
      carModels: [],
      carParts: [],
      carPartNames: [],
      partsOptions: [],
      isModelsSelectDisabled: true,
      isPartsSelectDisabled: true,
      selectedMake: null,
      selectedModel: null,
      selectedPart: null
    })
  }

  setMakeSelectsValue(item) {
    this.setState({
      selectedMake: item
    })
    this.handleMakesChange(item)
  }

  setModelSelectsValue(item) {
    this.setState({
      selectedModel: item
    })
    this.handleModelsSelectChange(item)
  }

  setModelsOptions(list) {
    let options = []
    list.map((model) => (
      options.push({label: model, value: model})
    ))
    this.setState({
      carModels: options,
      modelSelectPlaceholder: 'Модель автомобиля',
      isModelsSelectDisabled: false,
    })
  }

  setPartsOptions() {
    let options = []
    const parts = this.state.carPartNames
    parts.map((part) => (
      options.push({value: part, label: part})
    ))
    this.setState({
      partsOptions: options
    })
  }

  handleMakesChange(event) {
    if (event && event.value) {
      this.setState({
        modelSelectPlaceholder: 'Загружаем ...',
        isModelsSelectDisabled: true,
        isPartsSelectDisabled: true,
        carModels: null,
        carParts: null,
        partsOptions: null,
        selectedMake: {value: event.value, label: event.label},
        selectedModel: null,
        selectedPart: null
      })
      let modelsArray = this.props.carMakes.find(x => x.make === event.value)
      this.setModelsOptions(modelsArray.models)
    }
  }

  handleModelsSelectChange(event) {
    if (event && event.value) {
      const {selectedMake} = this.state;
      this.setState({
        selectedModel: {value: event.value, label: event.label},
        carParts: []
      })
      this.loadPartNames(selectedMake.value, event.value)
      this.loadParts(selectedMake.value, event.value)
    }
  }

  handlePartsSelectChange(event, eventType) {
    const { selectedMake, selectedModel, selectedPart } = this.state

    this.setState({
      selectedPart: event,
      carParts: []
    })

    if (event && event.value) {
      this.loadParts(selectedMake.value, selectedModel.value, event.value)
    }

    if (selectedMake && selectedModel && eventType.action === 'clear') {
      this.loadParts(selectedMake.value, selectedModel.value)
    }
  }

  handlePartsInputChange(event) {
    if (event) {
      const {selectedMake, selectedModel} = this.state;
      this.loadParts(selectedMake.value, selectedModel.value, event)
    }
  }

  loadPartNames(make, model) {
    let url = `/api/part_names?make=${make}&model=${model}`

    fetch(url)
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            carPartNames: result,
            isPartsSelectDisabled: false
          });
          this.setPartsOptions()
        },
        (error) => {
          this.setState({
            error: error
          });
          console.log(error)
        }
      )
  }

  loadParts(make, model, query = null, page = 1) {
    if (make && model) {
      let url = `/api/by_model?make=${make}&model=${model}&page=${page}`
      if (query !== null) {
        url = `/api/search?make=${make}&model=${model}&query=${query}&page=${page}`
      }

      fetch(url)
        .then(response => response.json())
        .then(
          (result) => {
            let parts = this.state.carParts || []
            parts.push(result.parts)
            const paginationVisible = (result.page < result.total_pages)
            this.setState({
              carParts: parts.flat(),
              page: result.page,
              totalPages: result.total_pages,
              paginationVisible: (paginationVisible),
              paginationLoading: false
            });
          },
          (error) => {
            this.setState({
              error: error
            });
            console.log(error)
          }
        )
    }
  }

  nextPage() {
    this.setState({paginationLoading: true})
    const {selectedMake, selectedModel, selectedPart, page, totalPages} = this.state
    const partName = selectedPart ? selectedPart.value : null
    if (page + 1 <= totalPages) {
      this.loadParts(selectedMake.value, selectedModel.value, partName, page +  1)
    }
  }

  getCartIds() {
    const url = `/api/cart`

    fetch(url)
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            cartIds: result.ids,
          });
        },
        (error) => {
          this.setState({
            error: error
          });
          console.log(error)
        }
      )
  }

  addToCart(id) {
    const ids = this.state.cartIds
    const data = new FormData();
    data.append(`part_id`, id);

    fetch(`/api/cart/add/`, {
      body: data,
      method: 'PATCH',
      headers: authHeaders(),
      credentials: 'same-origin'
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ errors: [], cartIds: data.ids })
      })
      .catch(error => {
        this.setState({errors: error})
        console.log(error)
      })
  }

  render() {
    const { carModels, isModelsSelectDisabled, modelSelectPlaceholder, partsOptions, carParts, isPartsSelectDisabled,
      carMakes, selectedMake, selectedModel, selectedPart, paginationVisible, paginationLoading, cartIds } = this.state;
    const makesListVisible =  !this.state.selectedMake
    const modelsListVisible =  !this.state.selectedModel
    return (
      <div className='cars-wrapper'>
        <div className='row mb-3'>
          <div className='col-lg-3 col-md-4 mb-3'>
            <Select
              options={carMakes}
              onChange={this.handleMakesChange}
              placeholder='Марка автомобиля'
              name='carMakesSelect'
              value={selectedMake}
            />
          </div>
          <div className='col-lg-3 col-md-4 mb-3'>
            <Select
              options={carModels}
              onChange={this.handleModelsSelectChange}
              isDisabled={isModelsSelectDisabled}
              placeholder={modelSelectPlaceholder}
              name='carModelsSelect'
              value={selectedModel}
            />
          </div>
          <div className='col-lg-3 col-md-4 mb-3'>
            <Select
              options={partsOptions}
              onChange={this.handlePartsSelectChange}
              onInputChange={this.handlePartsInputChange}
              isDisabled={isPartsSelectDisabled}
              isClearable={true}
              placeholder='Название запчасти'
              name='partsSelect'
              value={selectedPart}
            />
          </div>
          <div className='col-lg-3 col-md-12 text-lg-start text-end'>
            <button className='btn btn-outline-danger' onClick={this.initialize}>Сброс</button>
          </div>
        </div>
        <CarsList carsList={carMakes} select={this.setMakeSelectsValue} visible={makesListVisible}/>
        <CarsList carsList={carModels} select={this.setModelSelectsValue} visible={modelsListVisible}/>
        <CarParts
          carParts={carParts}
          nextPage={this.nextPage}
          paginationVisible={paginationVisible}
          paginationLoading={paginationLoading}
          addToCart={this.addToCart}
          cartIds={cartIds}
        />
      </div>
    )
  }
}

Cars.prototypes ={
  carMakes: PropTypes.shape
}

export default Cars
