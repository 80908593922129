import React from "react";
import PropTypes from "prop-types";
import CarPart from "./CarPart";
import Pagination from "./Pagination";


const CarParts = props => {
  const { carParts, paginationVisible, paginationLoading, cartIds, addToCart, nextPage } = props

  if (carParts && carParts.length > 0) {
    return(
      <div className='car-parts-wrapper'>
        <div className='row'>
          {carParts.map((part, index) => (
            <div className='col-lg-3 col-md-4 col-sm-6 mb-4' key={index}>
              <CarPart cartIds={cartIds} part={part} addToCart={addToCart} />
            </div>
          ))}
        </div>
        <div className='row'>
          <Pagination
            nextPage={nextPage}
            paginationVisible={paginationVisible}
            paginationLoading={paginationLoading}
          />
        </div>
      </div>
    )
  } else {
    return null
  }
}

CarParts.propTypes = {
  carParts: PropTypes.array,
  paginationVisible: PropTypes.bool,
  paginationLoading: PropTypes.bool.isRequired,
  cartIds: PropTypes.array,
  addToCart: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired
}

export default CarParts
