import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {authHeaders} from "../../helpers/auth-headers";

const Import = props => {
  const [isLocked, setIsLocked] = useState(props.default_state)
  const [lastImport, setLastImport] = useState(props.last_import)
  const [t, setT] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      getState();
      setT(t + 1)
    }, 5000);
    return () => clearTimeout(timer);
  })

  const getState = () => {
    fetch(props.url)
      .then(response => response.json())
      .then(
        (result) => {
          setIsLocked(result.is_locked)
          setLastImport(result.last_import)
        },
        (error) => {
          console.log(error)
        }
      )
  }

  const setState = () => {
    const data = new FormData();

    fetch(props.url, {
      method: 'POST',
      headers: authHeaders(),
      credentials: 'same-origin'
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setIsLocked(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  if (isLocked) {
    return (
      <div>
        <button disabled={true} className='btn btn-info mb-3'>
          <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" />
          Импортируем
        </button>
        <p>Последний импорт завершен {lastImport}</p>
      </div>
    );
  } else {
    return (
      <div>
        <button className='btn btn-info mb-3' onClick={setState}>
          {props.name}
        </button>
        <p>Последний импорт завершен {lastImport}</p>
      </div>

    );
  }
};

Import.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  last_import: PropTypes.string.isRequired,
  default_state: PropTypes.bool.isRequired
};

export default Import;
