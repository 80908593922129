import Rails from "@rails/ujs"
Rails.start()

// Support component names relative to this directory:
let componentRequireContext = require.context("components", true);
let ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require.context('../images', true)

import '../javascripts/public/globalEventListener'
import '../javascripts/public/tabsSwitcher'
import '../javascripts/public/contacts-map'

import 'bootstrap/dist/css/bootstrap.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import '../css/public/style.sass'
