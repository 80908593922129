import React, { Component } from "react";

const OPTIONS = [25, 50, 100];

class PerPageSelector extends Component {

  handlePerPageChange = () => {
    this.form.submit();
  };

  render() {
    const params = new URLSearchParams(window.location.search);
    let paramsComponents = [];
    let index = 0;
    for (const entry of params.entries()) {
      const [name, value] = entry;
      if (name !== 'per' && name !== 'page') {
        index += 1;
        paramsComponents.push(<input type="hidden" key={index} name={name} value={value}/>);
      }
    }

    return (
      <form
        ref={(el) => this.form = el}
        className="form-inline"
        method="get"
        acceptCharset="UTF-8"
        action={window.location.pathname}
      >
        <span className="mr-2">Записей на страницу</span>
        { paramsComponents }
        <select
          name="per"
          className="form-control input-sm"
          defaultValue={params.get('per') || OPTIONS[0]}
          onChange={this.handlePerPageChange}
        >
          { OPTIONS.map((per) => <option value={per} key={per}>{per}</option>) }
        </select>
      </form>
    )
  }

}

export default PerPageSelector;