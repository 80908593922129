document.addEventListener("DOMContentLoaded", function(event) {
  if (typeof ymaps === 'object') {
    const coords = document.querySelector("#js-yandex-map").dataset.coords.split(',');
    ymaps.ready(function () {
      const myMap = new ymaps.Map("js-yandex-map", {
        center: coords,
        zoom: 17,
        controls: []
      });

      const placeMark = new ymaps.Placemark(coords);

      myMap.geoObjects.add(placeMark);

      myMap.controls.add('zoomControl', { position: { left: '5px', top: '5px'} });
    });
  }
});
