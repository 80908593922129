import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";


const CarsSelect = props => {
  const {
    name,
    value,
    placeholder,
    options,
    isDisabled,
    isClearable,
    handleChange,
    handleInputChange
  } = props

  return(
    <div className='col-md-3'>
      <Select
        name={name}
        placeholder={placeholder}
        value={value}
        isDisabled={isDisabled}
        isClearable={isClearable}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </div>
  )
}

CarsSelect.prototypes ={
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isClearable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired
}

export default CarsSelect
