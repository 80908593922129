import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: true };
  }

  handleClickClose = () => {
    this.setState({ isVisible: false });
  }

  render() {
    const { closable, message, type } = this.props;
    const alertClass = classNames("alert", `alert-${type}`, { "alert-dismissible": closable });

    if (this.state.isVisible) {
      return (
        <div className={alertClass}>
          { closable && <button className="btn-close" data-dismiss="alert" onClick={this.handleClickClose} /> }
          <div dangerouslySetInnerHTML={{ __html: message }}/>
        </div>
      )
    } else {
      return null;
    }
  }
}

Alert.propTypes = {
  closable: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.oneOf(["success", "danger"]).isRequired,
};

export default Alert