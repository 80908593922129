import React, { useState } from "react";
import PropTypes from "prop-types";


const CartButton = props => {
  const [loading, setLoading] = useState(false)
  const { addToCart, cartIds, id } = props;

  const toCart = () => {
    setLoading(true)
    addToCart(id)
  }

  if (cartIds.includes(id)) {
    return(
      <a className="btn btn-primary btn-sm card-link w-100" href="/cart">В корзине</a>
    )
  }
  if (loading && !cartIds.includes(id)) {
    return (
      <button name="button" className="btn btn-primary btn-sm card-link w-100" disabled={true}>
        <span className="spinner-border spinner-border-sm me-1"/>
        Добваляем
      </button>
    )
  } else {
    return(
      <button name="button" className="btn btn-primary btn-sm card-link w-100" onClick={toCart}>
        Заказать
      </button>
    )
  }
}

CartButton.propTypes ={
  addToCart: PropTypes.func.isRequired,
  cartIds: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired
}

export default CartButton
