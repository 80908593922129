import React, { Component } from "react";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import PropTypes from "prop-types";
import Suneditor from "./Suneditor";


class MapEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coords: this.props.value.split(',')
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    this.setState({ coords: event.get('coords')})
  }

  render() {
    return (
      <div className='row mb-3'>
        <div className='col-md-3'>Карта</div>
        <div className='col-md-9'>
          <YMaps>
            <Map
              height='300px'
              width='100%'
              defaultState={{
                center: this.state.coords,
                zoom: 17 }}
              onClick={this.handleClick}
            >
              <Placemark geometry={this.state.coords} />
            </Map>
          </YMaps>
          <input
            className='form-control string optional'
            type='hidden'
            value={this.state.coords}
            name={this.props.name}
            id={this.props.id} />
        </div>
      </div>
    )
  }
}

Suneditor.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default MapEditor;
