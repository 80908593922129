import React from "react";
import ImageGallery from 'react-image-gallery';
import PropTypes from "prop-types";


const ImageSlider = props => {

  return(
    <div className='image-wrapper'>
      <ImageGallery
        items={props.images}
        showPlayButton={false}
        showBullets={true}
      />
    </div>
  )
}

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      original: PropTypes.string.isRequired,
      preview: PropTypes.string.isRequired,
      thumb: PropTypes.string.isRequired
    })
  ),
  alt: PropTypes.string.isRequired
}

export default ImageSlider;
