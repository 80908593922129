import React, { Component } from 'react';
import classNames from "classnames";
import {authHeaders} from "../helpers/auth-headers";


class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      total: 0,
      parts: []
    }

    this.loadParts = this.loadParts.bind(this)
    this.calculateTotal = this.calculateTotal.bind(this)
    this.removePart = this.removePart.bind(this)
    this.inputClassNames = this.inputClassNames.bind(this)
  }

  componentDidMount() {
    this.loadParts()
  }

  loadParts() {
    fetch(`/api/cart/items`)
      .then(response => response.json())
      .then(
        (result) => {
          this.setState({
            parts: result.parts,
            loading: false
          });
          this.calculateTotal()
        },
        (error) => {
          this.setState({
            error: error
          });
          console.log(error)
        }
      )
  }

  calculateTotal() {
    let total = 0
    this.state.parts.map(part => (
      total += Number.parseFloat(part.car_part.price)
    ))
    this.setState({ total: total })
  }

  removePart(id) {
    const data = new FormData();
    data.append(`part_id`, id);

    fetch(`/api/cart/remove`, {
      body: data,
      method: 'DELETE',
      headers: authHeaders(),
      credentials: 'same-origin'
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ parts: data.parts })
        this.calculateTotal()
      })
      .catch(error => {
        this.setState({errors: error})
        console.log(error)
      })
  }

  inputClassNames(input) {
    const {errors} = this.props
    return (errors && errors.hasOwnProperty(input)) ? 'form-control is-invalid' : 'form-control'
  }

  render() {
    const {parts, loading, total} = this.state
    const {params, action, csrf_token, errors} = this.props

    if (loading) {
      return (
        <div className='cart__parts'>
          Загружаем корзину ...
        </div>
      );
    }
    if (parts.length && !loading) {
      return (
        <div className='row'>
          {errors &&
            <div className='col-12 alert alert-danger'>
              Есть ошибки при заполнении формы заказа
            </div>
          }
          <div className='cart__parts col-md-8'>
            {parts.map(part => (
              <div key={part.car_part.id} className='row mb-5'>
                <div className='col-md-2'>
                  <img className='img-fluid' src={part.image} alt={part.car_part.name}/>
                </div>
                <div className='col-md-10'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='fs-4'>{part.car_part.name}</div>
                      <p>{`${part.car_part.make} ${part.car_part.model}`}</p>
                      <p className="text-danger cursor-pointer" onClick={() => this.removePart(part.car_part.id)}>Удалить</p>
                    </div>
                    <div className='col-md-4'>
                      <div className='fs-4 mt-3 text-end'>{Intl.NumberFormat('ru-RU').format(part.car_part.price)} р</div>
                    </div>
                  </div>

                </div>
              </div>
            ))}
          </div>
          <div className='col-md-4'>
            <form action={action} method={'POST'}>
              <input type='hidden' name="authenticity_token" value={csrf_token} />
              <div className='mb-3'>
                <label htmlFor='name' className='form-label'>Имя</label>
                <input type='text' className={this.inputClassNames('name')} id='name' name='order[name]'
                       placeholder='Ваше имя' defaultValue={(params && params.name) && params.name} />
                {(errors && errors.name) &&
                  <div id="validationName" className="invalid-feedback">
                    {errors.name}
                  </div>
                }
              </div>
              <div className='mb-3'>
                <label htmlFor="email" className='form-label'>Email</label>
                <input type='email' className={this.inputClassNames('email')} id='email' name='order[email]'
                       placeholder='name@example.com' defaultValue={(params && params.email) && params.email}/>
                {(errors && errors.email) &&
                  <div id="validationName" className="invalid-feedback">
                    {errors.email}
                  </div>
                }
              </div>
              <div className='mb-3'>
                <label htmlFor='phone' className='form-label'>Телефон</label>
                <input type='phone' className={this.inputClassNames('phone')} id='phone' name='order[phone]'
                       placeholder='+7XXXXXXXXXXX' defaultValue={(params && params.phone) && params.phone}/>
                {(errors && errors.phone) &&
                  <div id="validationName" className="invalid-feedback">
                    {errors.phone}
                  </div>
                }
              </div>
              <div className='fs-4 text-end mb-5'>
                {`Итого: ${Intl.NumberFormat('ru-RU').format(total)} р`}
                <button className='btn btn-danger ms-3'>Оформить заказ</button>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <h3>Ваша корзина пуста</h3>
      );
    }
  }
}

export default Cart;
