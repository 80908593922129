import React from "react";
import PropTypes from "prop-types";


const CarsList = props => {

  const { carsList, select, visible } = props

  if (visible) {
    return(
      <div className='row d-none d-md-flex'>
        {carsList.map((item, index) => (
          <div className='col-md-2' key={index}>
            <a href='#' onClick={() => select(item)}>{item.label}</a>
          </div>
        ))}
      </div>
    )
  } else {
    return null
  }
}

CarsList.propTypes ={
  carsList: PropTypes.array,
  select: PropTypes.func,
  visible: PropTypes.bool
}

export default CarsList