import React, { useState } from "react";
import classNames from 'classnames';
import PropTypes from "prop-types";


const ImageModal = props => {
  const { previewImage, image, alt } = props
  const [modalIsOpen, toggleModal] = useState(false)
  const modalClass = classNames(('modal fade'), { show: modalIsOpen })
  const closeModal = () => toggleModal(false)
  const openModal = () => toggleModal(true)

  return(
    <div className='image-wrapper'>
      <img src={previewImage} alt={alt} onClick={openModal}/>
      <div className={modalClass}>
        <div className='modal__wrapper' onClick={closeModal} />
        <div className='modal-dialog modal-dialog modal-lg modal-dialog-centered'>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Закрыть"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
              <img src={image} alt={alt} className='img-fluid mb-2' />
              <div className='text-end'><a href={image} target='_blank' className='btn btn-sm btn-warning'>Открыть на новой вкладке</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ImageModal.propTypes = {
  previewImage: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

export default ImageModal;
