import React, { useState } from 'react';
import CartButton from "./CartButton";
import PropTypes from 'prop-types';
import {authHeaders} from "../helpers/auth-headers";

const CartButtonWrapper = props => {
  const [cartIds, setCartIds] = useState(props.cartIds)
  const [errors, setErrors] = useState('')

  const cartIdsChange = (ids) => {
    setCartIds(ids)
  }

  const handleError = (response) => {
    if (!response.ok) {
      setErrors(response.status)
    }

    return response
  }

  const addToCart = () => {
    const data = new FormData();
    data.append('part_id', props.id.toString());

    fetch(`/api/cart/add/`, {
      body: data,
      method: 'PATCH',
      headers: authHeaders(),
      credentials: 'same-origin'
    })
      .then(handleError)
      .then(response => {
        return response.json();
      })
      .then(data => {
        cartIdsChange(data.ids)
      })
      .catch(error => {
        console.log(error)
      })
  }

  if (errors) {
    return (
      <div className='alert alert-warning'>
        <p>Произошла ошибка. Попробуйте <span style={{cursor: 'pointer'}} className='badge bg-primary' onClick={() => window.location.reload()}>обновить страницу</span> или зайти на сайт позже. Приносим свои извенения.</p>
      </div>
    )
  } else {
    return (
      <CartButton addToCart={addToCart} cartIds={cartIds} id={props.id} />
    );
  }
};

CartButtonWrapper.propTypes ={
  cartIds: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired
}

export default CartButtonWrapper;
