import React, {Component} from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone'
import {authHeaders} from "../helpers/auth-headers";
import PropTypes from 'prop-types';

const maxFiles = 5
const maxFileSize = 5

class ImageUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadingImagesCount: 0,
      files: this.props.images_cache || []
    };

    this.onDrop = this.onDrop.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
    this.submitFiles = this.submitFiles.bind(this)
    this.applyFiles = this.applyFiles.bind(this)
  }

  onDrop = (acceptedFiles) => {
    let files = this.state.files

    const newFiles = []
    acceptedFiles.map((file) => (
      files.find(uploaded => uploaded.name === file.name) === undefined ? newFiles.push(file) : null
    ))
    const destroyElementsCount = acceptedFiles.length >= maxFiles ? acceptedFiles.length - maxFiles : 0;
    acceptedFiles.splice(0, destroyElementsCount)

    if (files.length >= maxFiles) {
      files.splice(0, acceptedFiles.length)
    }

    if (newFiles.length > 0) {
      this.setState({uploadingImagesCount: newFiles.length})
      this.submitFiles(newFiles)
    }
  };

  submitFiles = (files) => {
    const data = new FormData();
    files.map(file => (
      data.append(`buyout_offer[images][]`, file)
    ))

    fetch('/api/buyouts', {
      body: data,
      method: 'POST',
      headers: authHeaders(),
      credentials: 'same-origin'
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.applyFiles(data)
      })
      .catch(error => {
        this.setState({errors: error})
        console.log(error)
      })
  }

  applyFiles = (data) => {
    let uploadedFiles = this.state.files
    const uploadingImagesCount = this.state.uploadingImagesCount - data.length

    uploadedFiles.push(data)

    this.setState({
      errors: [],
      files: uploadedFiles.flat(),
      uploadingImagesCount: uploadingImagesCount
    })
  }

  deleteFile = (index) => {
    let files = this.state.files
    files.splice(index, 1)
    this.setState({files: files})
  }

  isDisabled() {
    return this.state.files.length >= maxFiles
  }

  render() {
    const {image} = this.props
    const {files, uploadingImagesCount} = this.state
    const isDisabled = this.isDisabled()
    const dropzoneClasses = classNames(('buyout-dropzone mb-3'), { disabled: isDisabled })
    return (
      <div className='buyout-dropzone-wrapper mb-3'>
        <label className="form-label">Изображения авто</label>
        <Dropzone
          disabled={isDisabled}
          onDrop={this.onDrop}
          accept={'image/png, image/jpg, image/jpeg'}
          maxSize={maxFileSize * 1024 * 1024}
        >
          {({getRootProps, getInputProps}) => (
            <div className={dropzoneClasses} {...getRootProps()}>
              <input {...getInputProps()} id='images'/>
              {isDisabled && <p className='text-danger'>Вы загрузили максимальное количество файлов</p>}
              {!isDisabled && <p className='text-primary'>Перетащите файлы сюда или кликните для выбора файлов</p>}
              <p>{`Можно добавить не более ${maxFiles} изображений объемом ${maxFileSize} Мб`}</p>
            </div>
          )}
        </Dropzone>
        <div className='buyout-images-container'>
          {files.map((file, index) => (
            <div className='buyout-images-thumb' key={index}>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => this.deleteFile(index)} title='Удалить'
              />
              <img src={file.url} alt={file.name}/>
            </div>
          ))}

          {[...Array(uploadingImagesCount)].map((e, index) => (
            <div className='buyout-images-thumb uploading' key={`uploading${index}`}>
              <img src={image}  alt='uploading' />
              <div className="spinner-border text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ))}

          {files.map(file => (
            <input type='hidden' name='images_cache[]' value={file.images_cache} key={file.images_cache}/>
          ))}
        </div>
      </div>
    );
  }
}

ImageUploader.propTypes = {
  images_cache: PropTypes.shape({
    images_cache: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string
  }),
  image: PropTypes.string.isRequired
};

export default ImageUploader;
