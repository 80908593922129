function switchTab(event) {
  event.preventDefault();
  let target = event.target;
  document.querySelectorAll('.js-nav-link').forEach(function (item) {
    item.classList.remove('active');
  })
  document.querySelectorAll('.js-tab-content').forEach(function (item) {
    item.classList.remove('active');
  })
  event.target.classList.add('active');
  document.querySelector(`.js-tab-${target.dataset.target}`).classList.add('active');
}

addMultiEventListenerByClass('DOMContentLoaded ajax:success jsRendered',
  '.js-nav-link', 'click', switchTab)